export default {
    isMobile: false,
    isDEV: false,
    mainLogos: {
        desktop: require('@/assets/images/logo-desktop.svg'),
        mobile: require('@/assets/images/logo-mobile.svg')
    },
    productName: '',
    supportEmail: 'support@thecasualarcade.com',
    privacyEmail: 'support@thecasualarcade.com',
    alerts: {
        showAlert: false,
        alertMessage: 'default'
    },
    assetsPath: '',
    welcomeMessageSender: 'Jasmin Wild',
    siteId: null,
    globalTermsAndPolicies: '',
    productURL: '',
    user: {
        affiliate_id: null,
        age: null,
        email_confirmed: false,
        gender: "Male",
        locale: "en",
        roles: [],
        test_completed: false,
        username: "",
        settings: [],
        isPremium: false,

    },
};